import React from 'react';
import { faEnvelope, faMapMarkedAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AboutTitle,
  CardIconWrapper,
  CardLink,
  CardText,
  ContactCard,
  ContactCardsContainer,
  ContactTitle,
  ContentWrapper,
  Description,
  Hr,
} from './About';

export const CroatianAbout = () => {
  return (
    <ContentWrapper>
      <AboutTitle>O nama</AboutTitle>
      <Hr />
      <Description>
        Sobe Masle nalaze se u malom selu Brnaze na raskršću Sinj - Trilj - Split. Naš je mali obiteljski smještaj tri
        kilometra udaljen od Grada Sinja. Trudimo se gostima pružiti što ugodniji boravak, udobnost i odličan omjer
        cijene i kvalitete.
      </Description>
      <Description>
        Sobe su opremljene televizorom i klima uređajem te gosti imaju pristup besplatnoj Wi-Fi mreži. Gost također može
        parkirati svoje vozilo bilo gdje ispred smještaja na privatnom parkiralištu.
      </Description>

      <ContactTitle>Kontakt podaci</ContactTitle>
      <Hr />
      <ContactCardsContainer>
        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faEnvelope} />
          </CardIconWrapper>
          <Hr />
          <CardText>Email</CardText>
          <a href='mailto:roomsmasle@gmail.com'>roomsmasle@gmail.com</a>
        </ContactCard>

        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faPhone} />
          </CardIconWrapper>
          <Hr />
          <CardText>(Ante Masle):</CardText>
          <CardLink href='tel:+385917824073'>+385917824073</CardLink>

          <CardText>(Jurica Masle):</CardText>
          <a href='tel:+385958081806'>+385958081806</a>
        </ContactCard>

        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faMapMarkedAlt} />
          </CardIconWrapper>
          <Hr />
          <CardText>Adresa</CardText>
          <p> Brnaze 422B, 21230 Sinj, Hrvatska</p>
        </ContactCard>
      </ContactCardsContainer>
    </ContentWrapper>
  );
};
