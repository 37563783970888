export const HomeSliderImages = [
  { id: 1, src: '/images/img1.jpeg', alt: 'Outside' },
  { id: 2, src: '/images/img2.jpeg', alt: 'room' },
  { id: 3, src: '/images/img3.jpeg', alt: 'three bed room' },
  { id: 4, src: '/images/img4.jpeg', alt: 'three bed room' },
  { id: 5, src: '/images/img5.jpeg', alt: 'king size bed room' },
  { id: 6, src: '/images/img6.jpeg', alt: 'two bed room' },
  { id: 8, src: '/images/img8.jpeg', alt: 'two bed room' },
  { id: 9, src: '/images/img9.jpeg', alt: 'two bed room' },
  { id: 10, src: '/images/img10.jpeg', alt: 'three bed room' },
];
