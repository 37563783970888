import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;

  ${({ theme }) => `
	 background-color: ${theme.color.black}
	`}
`;

export const renderBackdrop = (props) => {
  return <Backdrop {...props} />;
};
