import React from 'react';

import { CroatianPricelist } from 'components/pricelist/CroatianPricelist';
import { EnglishPricelist } from 'components/pricelist/EnglishPricelist';

export const Pricelist = () => {
  const currentLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'croatian';

  return currentLanguage === 'croatian' ? <CroatianPricelist /> : <EnglishPricelist />;
};
