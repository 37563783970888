import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  width: 100%;
  background: black;

  ${({ theme }) => `
    padding: ${theme.spacing.medium} ${theme.spacing.superLarge} ;
    font-size: ${theme.fontSize.mediumLarge};

    @media(max-width: ${theme.breakpoints.mobile}){
      font-size: ${theme.fontSize.medium};
      padding: ${theme.spacing.medium} ${theme.spacing.medium};
    }
  `}
`;

const NavigationBar = styled.nav`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;

  ${({ theme }) => `
    font-size: ${theme.fontSize.mediumLarge};

    & > * {
      margin-left: ${theme.spacing.extraLarge};
    }
    
    @media(max-width: ${theme.breakpoints.tablet}){
      font-size: ${theme.fontSize.medium};
    }

    @media(max-width: ${theme.breakpoints.mobile}){
     justify-content: end;
    }
  `}
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  font-size: inherit;
  background-color: transparent;

  ${({ theme }) => `
    color: ${theme.color.lightGray};   
    font-size: ${theme.fontSize.mediumLarge};
    @media(max-width: ${theme.breakpoints.mobile}){
     
    font-size: ${theme.fontSize.medium};
    }
  `}
`;

const ChangeLanguageButton = styled.button`
  text-decoration: none;
  font-size: inherit;
  border: none;
  cursor: pointer;
  background-color: transparent;

  ${({ theme }) => `    
    color: ${theme.color.darkMint};
    font-size: ${theme.fontSize.mediumLarge};

    &:hover { 
      color: ${theme.color.mapGreen};
    }

    @media(max-width: ${theme.breakpoints.mobile}){
     font-size: ${theme.fontSize.medium};
    }
  `}
`;

export const Header = () => {
  const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'croatian';

  const handleChangeLanguageButtonClick = (selectedLanguage) => {
    localStorage.setItem('language', selectedLanguage);
    window.location.reload();
  };

  return (
    <HeaderWrapper>
      <NavigationLink to='/'>Home</NavigationLink>
      <NavigationBar>
        {language === 'english' ? (
          <NavigationLink to='/pricelist'>Pricelist</NavigationLink>
        ) : (
          <NavigationLink to='/cjenik'>Cjenik</NavigationLink>
        )}

        {language === 'english' ? (
          <ChangeLanguageButton
            onClick={() => {
              handleChangeLanguageButtonClick('croatian');
            }}
          >
            Vrati na Hrvatski
          </ChangeLanguageButton>
        ) : (
          <ChangeLanguageButton
            onClick={() => {
              handleChangeLanguageButtonClick('english');
            }}
          >
            Switch to English
          </ChangeLanguageButton>
        )}
      </NavigationBar>
    </HeaderWrapper>
  );
};
