import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;

  ${({ theme }) => `
    background-color: ${theme.color.lightGray};
    margin: ${theme.spacing.superExtraLarge} ${theme.spacing.large};
    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
     margin: 0;
     margin-bottom: ${theme.spacing.superExtraLarge};
     padding-top: ${theme.spacing.large};
    }
  `}
`;

const MapWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.color.lightGray};
  
    .leaflet-container {
      z-index: 0;
      height: 600px;
      width: 100%;
      @media (max-width: ${theme.breakpoints.landscapeTablet}) {        
        width: 90%;
        height: 500px;
        margin: auto;
      }      
      @media (max-width: ${theme.breakpoints.mobile}) {        
        height: 400px;
      }
    }
  `}
`;

const GoogleMapsLinkContainer = styled.div`
  background-color: transparent;
  text-align: center;
  ${({ theme }) => `
    margin: ${theme.spacing.large} 0;
  `}
`;

const GoogleMapsLink = styled.a`
  background-color: transparent;
`;

const googleMapsLocation =
  // eslint-disable-next-line max-len
  'https://www.google.com/maps/place/Sobe+Masle/@43.6801144,16.6476673,17z/data=!4m18!1m9!3m8!1s0x134aaebf8075367b:0x5516dc4bf48511a9!2sSobe+Masle!5m2!4m1!1i2!8m2!3d43.6801144!4d16.649856!3m7!1s0x134aaebf8075367b:0x5516dc4bf48511a9!5m2!4m1!1i2!8m2!3d43.6801144!4d16.649856';

export const Map = ({ language }) => {
  const position = [43.6802482, 16.6498253];
  return (
    <ContentWrapper>
      <MapWrapper>
        <MapContainer center={position} zoom={13} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker position={position}>
            <Popup>
              Sobe Masle <br />
              BRNAZE 422B <br /> 21230 Sinj <br /> Hrvatska
            </Popup>
          </Marker>
        </MapContainer>
      </MapWrapper>
      <GoogleMapsLinkContainer>
        <GoogleMapsLink target='_blank' rel='noreferrer' href={googleMapsLocation}>
          {language === 'croatian' ? 'OVDJE nas možete naći na Google kartama' : 'You can find us HERE on Google Maps'}
        </GoogleMapsLink>
      </GoogleMapsLinkContainer>
    </ContentWrapper>
  );
};
