import React from 'react';
import StarRatings from 'react-star-ratings';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CompanyDescription,
  DownloadButton,
  DownloadButtonWrapper,
  DownloadLink,
  MainContentWrapper,
  Price,
  PricelistSubtitle,
  PricelistTitle,
  PricesSection,
} from './Pricelist';

export const CroatianPricelist = () => {
  return (
    <MainContentWrapper>
      <CompanyDescription>SOBE U DOMAĆINSTVU, Ur.br.21281-06/6-01-2, VL.ANTE MASLE, BRNAZE 422B</CompanyDescription>

      <PricelistTitle>Cjenik</PricelistTitle>

      <StarRatings rating={3} numberOfStars={3} starRatedColor='gold' starDimension='40px' starSpacing='2px' />

      <PricelistSubtitle>Usluga smještaja u sobama 1.(prve) kategorije</PricelistSubtitle>

      <PricesSection>
        <Price>Noćenje u jednoposteljnoj sobi - 150kn</Price>

        <Price>Noćenje u dvoposteljnoj sobi - 240kn</Price>

        <Price>Noćenje u troposteljnoj sobi - 360kn</Price>
      </PricesSection>

      <PricelistSubtitle>Doručak nije uračunat u cijenu</PricelistSubtitle>

      <PricesSection>
        <Price>Pansion -180kn</Price>

        <Price>Polupansion - 160kn</Price>

        <Price>Cijena doručka po osobi - 30kn</Price>
      </PricesSection>

      <PricelistSubtitle>Boravišna pristojba je u cijeni usluge</PricelistSubtitle>

      <DownloadButtonWrapper>
        <DownloadLink download href='/pricelist.xlsx'>
          <DownloadButton>
            <FontAwesomeIcon icon={faDownload} />
            <p>preuzmi cjenik</p>
          </DownloadButton>
        </DownloadLink>
      </DownloadButtonWrapper>
    </MainContentWrapper>
  );
};
