import React from 'react';
import styled from 'styled-components';

import { CroatianAbout } from 'components/home/CroatianAbout';
import { EnglishAbout } from 'components/home/EnglishAbout';
import { HomeSlider } from 'components/home/HomeSlider';
import { Map } from 'components/Map';

const HeaderImageWrapper = styled.div`
  background-image: url(/images/img7.jpeg);
  background-size: cover;

  ${({ theme }) => `
   padding: ${theme.spacing.gigantic} 0;
    @media (max-width: ${theme.breakpoints.mobile}) {     
     padding: ${theme.spacing.superLarge} 0;
    }
  `}
`;

const HeaderTextWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  ${({ theme }) => `
    padding: ${theme.spacing.superLarge} ${theme.spacing.gigantic};
    @media (max-width: ${theme.breakpoints.mobile}) {     
     padding: ${theme.spacing.medium} ${theme.spacing.large};
    }
  `}
`;

const HeaderText = styled.h1`
  text-align: center;
  background-color: transparent;
  ${({ theme }) => `
   font-size: ${theme.fontSize.title};
    @media (max-width: ${theme.breakpoints.tablet}) {     
     font-size: ${theme.fontSize.subtitle};
    }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  ${({ theme }) => `  
    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
      flex-direction: column;
    }
  `}
`;

export const Home = () => {
  const currentLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'croatian';

  return (
    <>
      <HeaderImageWrapper>
        <HeaderTextWrapper>
          <HeaderText>{currentLanguage === 'croatian' ? 'Sobe Masle' : 'Masle Rooms'}</HeaderText>
        </HeaderTextWrapper>
      </HeaderImageWrapper>
      <HomeSlider />
      <ContentWrapper>
        {currentLanguage === 'croatian' ? <CroatianAbout /> : <EnglishAbout />}
        <Map language={currentLanguage} />
      </ContentWrapper>
    </>
  );
};
