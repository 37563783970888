import React from 'react';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { renderBackdrop } from './Backdrop';
import { OverlayModal } from './modalComponents';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  ${({ theme }) => `
    padding: ${theme.spacing.small};
  `}
  .fa-chevron-left,
  .fa-chevron-right, 
  .fa-times {
    cursor: pointer;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ModalImageWrapper = styled.div`
  width: 70vw;
  max-width: 70vw;
`;

const ModalImage = styled.img`
  width: 100%;
  height: 100%;

  ${({ theme }) => `
    padding: ${theme.spacing.small};
  `}
`;

export const HomeImageModal = ({ image, closeModal, onLeftIconClick, onRightIconClick }) => {
  return (
    <OverlayModal show renderBackdrop={renderBackdrop} onHide={closeModal}>
      <ModalWrapper>
        <FontAwesomeIcon icon={faTimes} onClick={closeModal} />
        <ModalContentWrapper>
          <FontAwesomeIcon icon={faChevronLeft} onClick={onLeftIconClick} />
          <ModalImageWrapper>
            <ModalImage src={image.src} alt={image.alt} />
          </ModalImageWrapper>
          <FontAwesomeIcon icon={faChevronRight} onClick={onRightIconClick} />
        </ModalContentWrapper>
      </ModalWrapper>
    </OverlayModal>
  );
};
