import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
     width: 80%;
    }
    @media (max-width: ${theme.breakpoints.mobile}) {
     width: 90%;
    }
  `};
`;

const LogoImage = styled.img`
  width: calc(0.3 * 100vw);
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
     width: calc(0.6 * 100vw);
    }
  `};
`;

const Text = styled.p`
  text-align: center;
  ${({ theme }) => `
    color: ${theme.color.black};  
    }
  `}
`;

export const NotFound = () => {
  const currentLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'croatian';
  return (
    <ContentWrapper>
      <LogoImage src='/logo512.png' alt='logo' />
      <Text>
        {currentLanguage === 'croatian'
          ? 'Osim ako Vam želja nije bila pobliže pogledati naš logo, na krivoj ste lokaciji :)'
          : 'Unless you wanted to take a closer look at our logo, You are in the wrong place :)'}
      </Text>
    </ContentWrapper>
  );
};
