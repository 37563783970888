import styled from 'styled-components';

export const ContentWrapper = styled.section`
  ${({ theme }) => `
    background-color: ${theme.color.lightGray};
    color: ${theme.color.black};
    margin: ${theme.spacing.superLarge} ${theme.spacing.medium};
    padding: ${theme.spacing.large};
  `}
`;

export const AboutTitle = styled.h1`
  ${({ theme }) => `
    font-size: ${theme.fontSize.subtitle};
  `}
`;

export const Hr = styled.hr`
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${({ theme }) => `
    margin: ${theme.spacing.medium} 0
  `}
`;

export const Description = styled.p`
  text-align: justify;
  ${({ theme }) => `
    margin: ${theme.spacing.medium} 0;
    font-size: ${theme.fontSize.mediumLarge};
  `}
`;

export const ContactTitle = styled.h2`
  ${({ theme }) => `
    font-size: ${theme.fontSize.large};
  `}
`;

export const ContactCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}) {     
     flex-direction: column;
    }
  `}
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  ${({ theme }) => `
    background-color: ${theme.color.mapGreen};
    color: ${theme.color.gray};
    margin: ${theme.spacing.extraSmall};
    padding: ${theme.spacing.large} ${theme.spacing.medium};
    @media (max-width: ${theme.breakpoints.mobile}) {     
     width: 100%;
     margin: ${theme.spacing.medium} 0;
    }
  `}
`;

export const CardIconWrapper = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSize.large};
  `}
`;

export const CardText = styled.p`
  font-weight: bold;
`;

export const CardLink = styled.a`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.medium};
  `}
`;
