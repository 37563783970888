import { Modal } from 'react-overlays';
import styled from 'styled-components';

export const OverlayModal = styled(Modal)`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;

  ${({ theme }) => `
    color: ${theme.color.black};
    background-color: ${theme.color.white};
  `}
`;
