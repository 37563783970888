import React from 'react';
import StarRatings from 'react-star-ratings';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  CompanyDescription,
  DownloadButton,
  DownloadButtonWrapper,
  DownloadLink,
  MainContentWrapper,
  Price,
  PricelistSubtitle,
  PricelistTitle,
  PricesSection,
} from './Pricelist';

export const EnglishPricelist = () => {
  return (
    <MainContentWrapper>
      <CompanyDescription>SOBE U DOMAĆINSTVU, Ur.br.21281-06/6-01-2, VL.ANTE MASLE, BRNAZE 422B</CompanyDescription>

      <PricelistTitle>Pricelist</PricelistTitle>

      <StarRatings rating={3} numberOfStars={3} starRatedColor='gold' starDimension='40px' starSpacing='2px' />

      <PricelistSubtitle>Accommodation in 1.(first) category rooms</PricelistSubtitle>

      <PricesSection>
        <Price>Room with one bed - 150kn</Price>

        <Price>Room with two beds - 240kn</Price>

        <Price>Room with three beds - 360kn</Price>
      </PricesSection>

      <PricelistSubtitle>All prices above are without breakfast included</PricelistSubtitle>

      <PricesSection>
        <Price>One person - breakfast, lunch, dinner - 180kn</Price>

        <Price>One person - breakfast, lunch - 160kn</Price>

        <Price>Breakfast - 30kn</Price>
      </PricesSection>

      <PricelistSubtitle>
        The price includes a residence tax and other costs related to accommodation.
      </PricelistSubtitle>

      <DownloadButtonWrapper>
        <DownloadLink download href='/pricelist.xlsx'>
          <DownloadButton>
            <FontAwesomeIcon icon={faDownload} />
            <p>download pricelist</p>
          </DownloadButton>
        </DownloadLink>
      </DownloadButtonWrapper>
    </MainContentWrapper>
  );
};
