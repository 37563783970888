import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { HomeSliderArrow } from 'components/home/HomeSliderArrow';

export const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  adaptiveHeight: true,
  draggable: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnFocus: true,
  nextArrow: <HomeSliderArrow icon={faChevronRight} position='next' />,
  prevArrow: <HomeSliderArrow icon={faChevronLeft} position='prev' />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
