import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { HomeSliderImages } from 'constants/HomeImages';
import { settings } from 'constants/HomeSliderSettings';
import styled from 'styled-components';

import { HomeImageModal } from 'components/modal/HomeImageModal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderWrapper = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;

  ${({ theme }) => `
    padding: 0 ${theme.spacing.superLarge};

    .slick-arrow {
     color: ${theme.color.gray};
     height: 1rem;
    }
    
    .slick-slider {
      display:flex;
      align-items: center;
    }

    .fa-chevron-left, .fa-chevron-right {
      cursor: pointer;
    }
    @media (max-width: ${theme.breakpoints.desktop}) {
     padding: 0 ${theme.spacing.large};
    }

    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
     padding: 0 ${theme.spacing.large};
    }

    @media (max-width: ${theme.breakpoints.mobile}) {
     padding: 0 ${theme.spacing.small};
     padding-top: ${theme.spacing.small};
    }
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex !important;
  justify-content: center;
  ${({ theme }) => `
    padding: ${theme.spacing.small};
    @media (max-width: ${theme.breakpoints.mobile}) {
     padding: 0;
    }
  `}
`;

const SliderImage = styled.img`
  &:hover {
    cursor: pointer;
  }

  height: calc(0.25 * 100vh);

  ${({ theme }) => `
    padding: ${theme.spacing.small};

    @media (max-width: ${theme.breakpoints.tablet}) {
     height: calc(0.20 * 100vh);
    }

    @media (max-width: ${theme.breakpoints.mobile}) {
     height: calc(0.25 * 100vh);
    }

    @media (max-width: ${theme.breakpoints.smallMobile}) {
     height: calc(0.22 * 100vh);
    }
  `}
`;

export const HomeSlider = () => {
  const slider = useRef();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onSliderImageClick = (image, imageIndex) => {
    openModal();
    setCurrentImage(image);
    setCurrentImageIndex(imageIndex);
  };

  const openModal = () => setIsImageModalOpen(true);
  const closeModal = () => setIsImageModalOpen(false);

  const onLeftIconClick = () => {
    if (currentImageIndex > 0) {
      setCurrentImage(HomeSliderImages[currentImageIndex - 1]);
      setCurrentImageIndex(currentImageIndex - 1);
      slider.current.slickPrev();
    } else if (currentImageIndex === 0) {
      setCurrentImage(HomeSliderImages[HomeSliderImages.length - 1]);
      setCurrentImageIndex(HomeSliderImages.length - 1);
    }
  };

  const onRightIconClick = () => {
    if (currentImageIndex < HomeSliderImages.length - 1) {
      setCurrentImage(HomeSliderImages[currentImageIndex + 1]);
      setCurrentImageIndex(currentImageIndex + 1);
      slider.current.slickNext();
    } else if (currentImageIndex === HomeSliderImages.length - 1) {
      setCurrentImage(HomeSliderImages[0]);
      setCurrentImageIndex(0);
    }
  };

  return (
    <SliderWrapper>
      <Slider {...settings} ref={slider}>
        {HomeSliderImages.map((image, index) => (
          <ImageWrapper key={image.id}>
            <SliderImage src={image.src} alt='img' onClick={() => onSliderImageClick(image, index)} />
          </ImageWrapper>
        ))}
      </Slider>

      {isImageModalOpen && (
        <HomeImageModal
          image={currentImage}
          closeModal={closeModal}
          onLeftIconClick={onLeftIconClick}
          onRightIconClick={onRightIconClick}
        />
      )}
    </SliderWrapper>
  );
};
