import React from 'react';
import { faEnvelope, faMapMarkedAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AboutTitle,
  CardIconWrapper,
  CardLink,
  CardText,
  ContactCard,
  ContactCardsContainer,
  ContactTitle,
  ContentWrapper,
  Description,
  Hr,
} from './About';

export const EnglishAbout = () => {
  return (
    <ContentWrapper>
      <AboutTitle>About us</AboutTitle>
      <Hr />
      <Description>
        We are located in Brnaze, on the Sinj - Trilj - Split crossroads. Our small family accommodation is 3 kilometers
        away from Sinj. We always give our best to offer a warm welcome to our guests, assure they have a comfortable
        stay for affordable prices.
      </Description>
      <Description>
        Our rooms are equipped with TV, air conditioning and free Wi-Fi access is provided. The guest can also park
        their vehicle anywhere in front of the accommodation on our private parking.
      </Description>
      <ContactTitle>Contact info</ContactTitle>
      <Hr />
      <ContactCardsContainer>
        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faEnvelope} />
          </CardIconWrapper>
          <Hr />
          <CardText>Email</CardText>
          <a href='mailto:roomsmasle@gmail.com'>roomsmasle@gmail.com</a>
        </ContactCard>

        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faPhone} />
          </CardIconWrapper>
          <Hr />
          <CardText>(Ante Masle):</CardText>
          <CardLink href='tel:+385917824073'>+385917824073</CardLink>

          <CardText>(Jurica Masle):</CardText>
          <a href='tel:+385958081806'>+385958081806</a>
        </ContactCard>

        <ContactCard>
          <CardIconWrapper>
            <FontAwesomeIcon icon={faMapMarkedAlt} />
          </CardIconWrapper>
          <Hr />
          <CardText>Address</CardText>
          <p> Brnaze 422B, 21230 Sinj, Croatia</p>
        </ContactCard>
      </ContactCardsContainer>
    </ContentWrapper>
  );
};
