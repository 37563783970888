import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Home } from 'pages/Home';
import { Pricelist } from 'pages/Pricelist';

import { NotFound } from 'components/NotFound';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/pricelist' component={Pricelist} />
      <Route exact path='/cjenik' component={Pricelist} />
      <Route path='/404' component={NotFound} />
      <Redirect to='/404' />
    </Switch>
  );
};
