import styled from 'styled-components';

export const MainContentWrapper = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  width: 70%;
  margin: auto;

  ${({ theme }) => `
    background-color: ${theme.color.white};
    padding-top: ${theme.spacing.superExtraLarge};
    padding-right: ${theme.spacing.medium};
    padding-left: ${theme.spacing.medium};
    color: ${theme.color.gray};

    @media (max-width: ${theme.breakpoints.landscapeTablet}) {
     padding-top: ${theme.spacing.superLarge};        
      width: 90%;
    } 
    
    @media (max-width: ${theme.breakpoints.tablet}) {        
      width: 100%;
      height: 100vh;
    } 
    
    @media (max-width: ${theme.breakpoints.mobile}) {
      padding: 0 ${theme.spacing.small};
      height: 100%;
    }
  `};
`;

export const CompanyDescription = styled.p`
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}) {
     text-align: center;
    }
  `};
`;

export const PricelistTitle = styled.h1`
  font-weight: bold;

  ${({ theme }) => `
    margin-top: ${theme.spacing.extraLarge};
    margin-bottom: ${theme.spacing.medium};
    font-size: ${theme.fontSize.title};
  `};
`;

export const PricelistSubtitle = styled.h2`
  text-align: center;
  ${({ theme }) => `
    margin: ${theme.spacing.extraLarge} 0;
    font-size: ${theme.fontSize.large};
    @media (max-width: ${theme.breakpoints.mobile}) {
     font-size: ${theme.fontSize.mediumLarge};
    }
  `};
`;

export const PricesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => `;
    font-size: ${theme.fontSize.mediumLarge};
    margin-bottom: ${theme.spacing.extraLarge};
    @media (max-width: ${theme.breakpoints.mobile}) {
     font-size: ${theme.fontSize.medium};
    }
  `};
`;

export const Price = styled.h3`
  word-wrap: break-word;
`;

export const DownloadButtonWrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing.extraLarge} 0;
  `};
`;

export const DownloadLink = styled.a`
  text-decoration: none;
`;

export const DownloadButton = styled.button`
  display: flex;
  cursor: pointer;

  ${({ theme }) => `;
    font-size: ${theme.fontSize.medium};
    padding: ${theme.spacing.medium} ${theme.spacing.extraLarge};
  
    & > p {
        margin-left: ${theme.spacing.mediumSmall};
    }
  `};
`;
