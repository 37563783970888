export const defaultTheme = {
  color: {
    black: '#0B0C10',
    gray: '#1F2833',
    lightGray: '#f5ebeb',
    white: '#fff',
    mint: '#66FCF1',
    mapGreen: '#9bc08d',
    darkMint: '#45A29E',
  },
  spacing: {
    extraSmall: '0.25rem',
    small: '0.5rem',
    mediumSmall: '0.75rem',
    medium: '1rem',
    mediumLarge: '1.25rem',
    large: '1.5rem',
    extraLarge: '2rem',
    superLarge: '4rem',
    superExtraLarge: '7rem',
    gigantic: '11rem',
  },
  breakpoints: {
    smallMobile: '425px',
    mobile: '600px',
    tablet: '850px',
    landscapeTablet: '1080px',
    smallDesktop: '1440px',
    desktop: '1920px',
    largeDesktop: '2560px',
  },
  fontSize: {
    small: '0.5rem',
    medium: '1rem',
    mediumLarge: '1.5rem',
    large: '2rem',
    subtitle: '3rem',
    title: '4rem',
  },
};
