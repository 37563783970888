import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'themes/defaultTheme';

import { Header } from 'components/Header';
import { AppRoutes } from 'core/router/Routes';

export const App = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Header />
          <main>
            <AppRoutes />
          </main>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
};
